import * as React from 'react'
import cx from 'classnames'
import { GatsbyFluidImage, GatsbyFixedImage } from '../../data/types'
import GatsbyImage from 'gatsby-image'

interface Props {
  src?: string
  alt: string
  style?: any
  ratio?: number
  className?: string
  // use gatsby-image instead
  sizes?: GatsbyFluidImage
  fluid?: GatsbyFluidImage
  fixed?: GatsbyFixedImage
}

export default class CoverImage extends React.Component<Props, {}> {
  static defaultProps = {
    ratio: undefined,
    className: undefined,
    style: undefined,
  }
  public render() {
    const { src, alt, ratio, style, className, ...props } = this.props
    return (
      <div
        className={cx('cover-image', className)}
        style={{
          ...(style ? style : {}),
          ...(ratio ? { paddingBottom: `${ratio * 100}%` } : {}),
        }}
      >
        {props.sizes || props.fluid || props.fixed ? (
          <GatsbyImage {...props} alt={alt} />
        ) : (
          <img src={src} alt={alt} />
        )}
      </div>
    )
  }
}
